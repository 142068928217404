<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item label="报警编号">
            <el-input  placeholder="报警编号" maxlength="30" v-model="formInline.alarmCode"></el-input>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <!--<el-form-item :label="$t('searchModule.Belonging_parking_lot')">-->
              <!--<el-select v-model.trim="formInline.operationId" filterable  size="15">-->
              <!--<el-option label="全部" value=""></el-option>-->
              <!--<el-option :label="value.operationName" :value="value.operationId" :key="value.operationId" v-for="value in tenantList"></el-option>-->
              <!--</el-select>-->
              <!--</el-form-item>-->
              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Alarm_time')">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  :editable="false"
                  v-model.trim="dateRange"
                  type="daterange"
                  placeholder="请选择时间范围"
                  @change="dateFmt(dateRange)"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <!--<el-button type="primary" icon="el-icon-search" @click="lookParkRecordList()" :loading="loading">查看</el-button>-->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="200"
            type="index"
            v-if="$route.meta.authority.button.detail"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.detail"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block" style="text-align: right">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  data() {
    const start = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    const end = new Date();
    // console.log(start, end);
    return {
      state: "",
      page: 1,
      pageSize: 15,
      total: 0,
      dateRange: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
      tenantList: [],
      loading: false,
      tableData: [],
      formInline: {
        // alarmCode: '',
        parkId: "",
        operationId: "",
        berthCode: "",
        startTime: dateFormat(start, "yyyy-MM-dd"),
        endTime: dateFormat(end, "yyyy-MM-dd"),
      },
      tableCols: [
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "type",
          label: "报警类型",
          width: "",
          formatter: (type) => {
            if (type.type == 1) {
              return "车牌遮挡";
            } else {
              return "无";
            }
          },
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "reportTime",
          label: "报警时间",
          width: "",
          formatter: (val) => {
            return dateFormat(new Date(1 * val.reportTime));
          },
        },
      ],
    };
  },
  components: {
    myComponent,
  },
  methods: {
    dateFmt(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd");
        let endTime = dateFormat(data[1], "yyyy-MM-dd");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    handleCommand(com, data) {
      this.$router.push({ path: "/plateAlertDetail", query: data });
    },
    lookParkRecordList() {},
    showLog() {
      if (this.dateRange) {
        let [date1, date2] = this.dateRange;
        var time = new Date(date2) - new Date(date1);
        time = time / (24 * 60 * 60 * 1000);
        if (time > 30) {
          this.$alert("搜索时间范围不能超过31天", "提示");
          return false;
        }
        return true;
      } else {
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    detailShow(e) {
      // console.log(e, 'ppp')
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      if (!this.showLog()) return;
      let url = "/acb/2.0/plateCover/query";
      let [startTime, endTime] = this.dateRange;
      this.loading = true;
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
            startTime,
            endTime,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  mounted() {
    this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
